import React, { Component } from 'react';
import {FormattedMessage} from 'react-intl';

import '../scss/burguer_menu.scss';

class BurguerMenu extends Component {
    constructor(props)
    {
        super(props);
        this.state = { open : false };
    }

    setOpen(value){
        console.log('entra');
        this.setState({
            open: value
        });
    }

    render() {
        return (
            <div className="d-lg-none">
                <button className={(this.state.open) ? "btn-burguer open" : "btn-burguer"} onClick={() => this.setOpen(!this.state.open)}>
                    <div></div>
                    <div></div>
                    <div></div>
                </button>
                <nav className={(this.state.open ? 'burguer-nav shadow open' : 'burguer-nav shadow')}>
                    <a href="/">
                        <FormattedMessage
                            id="app.project"
                            defaultMessage="Proyecto"
                        ></FormattedMessage>
                    </a>
                    <a href="/cotizador">
                        <FormattedMessage
                            id="app.quoter"
                            defaultMessage="Cotizador"
                        ></FormattedMessage>
                    </a>
                    <a href="/galeria">
                        <FormattedMessage
                            id="app.gallery"
                            defaultMessage="Galería"
                        ></FormattedMessage>
                    </a>
                    <a href="/contacto">
                        <FormattedMessage
                            id="app.location"
                            defaultMessage="Ubicación"
                        ></FormattedMessage>
                    </a>
                    <a href="/contacto#location-2">
                        <FormattedMessage
                            id="app.contact"
                            defaultMessage="Contacto"
                        ></FormattedMessage>
                    </a>
                    <a href="/contact#location-2">
                        <FormattedMessage
                            id="app.virtual_tour"
                            defaultMessage="Recorrido Virtual"
                        ></FormattedMessage>
                    </a>
                </nav>
            </div>
        );
    }
}

export default BurguerMenu;
