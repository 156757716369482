import React, { Component } from 'react';
import { Nav, Col } from 'react-bootstrap';
import logoWhite from '../assets/logo_white.svg';
import '../scss/navbar.scss';
import {FormattedMessage} from 'react-intl';
import BurguerMenu from './BurguerMenu';

class Navbar extends Component {
	setLanguage(lan){
		console.log('entra setlanguage');
		localStorage.setItem('language', lan);
		window.location.reload(false);
	}
    render() {
        return (
            <div className="main-nav shadow-sm">
				<Col className="text-right pr-5 pt-2 languages">
					<span className="text-white">
						<a className="text-white" href="#!" onClick={() => {this.setLanguage('es')}}>ES</a> / <a className="text-white" href="#!" onClick={() => {this.setLanguage('en')}}>EN</a>
					</span>
					<br></br>
					<img className="mt-2 d-lg-none img" src={logoWhite} alt="Logo"></img>
				</Col>
				<Nav className="justify-content-center pt-3 pb-3" activeKey="/home">
					<Nav.Item>
						<Nav.Link href="/">
							<FormattedMessage
								id="app.project"
								defaultMessage="Proyecto"
							></FormattedMessage>
						</Nav.Link>
					</Nav.Item>
					<Nav.Item>
						<Nav.Link href="/cotizador">
							<FormattedMessage
								id="app.quoter"
								defaultMessage="Cotizador"
							></FormattedMessage>
						</Nav.Link>
					</Nav.Item>
					<Nav.Item>
						<Nav.Link href="/galeria">
							<FormattedMessage
								id="app.gallery"
								defaultMessage="Galería"
							></FormattedMessage>
						</Nav.Link>
					</Nav.Item>
					<Nav.Item>
						<Nav.Link eventKey="link-2">
							<img src={logoWhite} alt="Logo" style={{width: "100%"}}></img>
						</Nav.Link>
					</Nav.Item>
					<Nav.Item>
						<Nav.Link href="/contacto">
							<FormattedMessage
								id="app.location"
								defaultMessage="Ubicación"
							></FormattedMessage>
						</Nav.Link>
					</Nav.Item>
					<Nav.Item>
						<Nav.Link href="/contacto#location-2">
							<FormattedMessage
								id="app.contact"
								defaultMessage="Contacto"
							></FormattedMessage>
						</Nav.Link>
					</Nav.Item>
					<Nav.Item>
						<Nav.Link href="/recorrido-virtual">
							<FormattedMessage
								id="app.virtual_tour"
								defaultMessage="Recorrido Virtual"
							></FormattedMessage>
						</Nav.Link>
					</Nav.Item>
				</Nav>
				<BurguerMenu></BurguerMenu>
            </div>
        );
    }
}

export default Navbar;