import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
// import './notfound.css';

class NotFound extends Component {
    constructor(props) {
        super(props);
        // No llames this.setState() aquí!
        this.state = { class: "cont_principal" };
    }
    componentDidMount(){
        this.setState({ class: "cont_principal cont_error_active"})
    }
    render() {
        return (
            <div>
                <div className={this.state.class}>
                    <div className="cont_error">
                        <h1>Oops</h1>  
                        <p><FormattedMessage
                            id="app.errors.the_page_you_are_looking_is_missing"
                            defaultMessage="The Page you're looking for isn't here."
                        ></FormattedMessage></p>
                    </div>
                    <div className="cont_aura_1"></div>
                    <div className="cont_aura_2"></div>
                </div>
            </div>
        );
    }
}

export default NotFound;