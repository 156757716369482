import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import './scss/quoter.scss';

class Quoter extends Component {
    componentDidMount(){
    }
    render() {
        return (
            <div>
                <Container className="pt-4 pb-4">
                    <Row>
                        <Col xl="7">
                            <h1 className="text-blue-1 text-uppercase">
                                <FormattedMessage
                                    id="app.cotiza_tu_lote_con_nosotros"
                                    defaultMessage="COTIZA TU LOTE CON NOSOTROS"
                                ></FormattedMessage>
                            </h1>
                        </Col>
                        <Col xl="5" className="d-flex flex-column justify-content-center">
                            <ul className="status list-unstyled d-flex justify-content-center">
                                <li className="disponible">
                                    <FormattedMessage
                                        id="app.disponible"
                                        defaultMessage="Disponible"
                                    ></FormattedMessage>
                                </li>
                                <li className="apartado">
                                    <FormattedMessage
                                        id="app.apartado"
                                        defaultMessage="Apartado"
                                    ></FormattedMessage>
                                </li>
                                <li className="vendido">
                                    <FormattedMessage
                                        id="app.vendido"
                                        defaultMessage="Vendido"
                                    ></FormattedMessage>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
                <Container fluid>
                    <Row>
                        <Col id="masterplan" className="bg-blue">
                            <div className="text-center" style={{minHeight:"200px"}}>
                                <h5><FormattedMessage id="app.loading" defaultMessage="Cargando..."></FormattedMessage></h5>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default Quoter;