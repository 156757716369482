import Navbar from "./components/Navbar";
import Home from './Home.js';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import SwiperCore, { Autoplay, Pagination } from 'swiper';
import NotFound from "./components/errors/404/NotFound";
import Contact from "./Contact";
import Quoter from "./Quoter";
import BurguerMenu from "./components/BurguerMenu";
import Gallery from "./Gallery";
import Footer from "./components/Footer";
import Recorrido from "./Recorrido";


function App() {
	SwiperCore.use([Autoplay, Pagination])
	return (
		<Router>
			<div className="App">
				<Navbar></Navbar>
				<BurguerMenu></BurguerMenu>
				<Switch>
					<Route path="/" exact>
						<Home />
					</Route>
					<Route path="/contacto">
						<Contact></Contact>
					</Route>
					<Route path="/galeria">
						<Gallery></Gallery>
					</Route>
					<Route path="/cotizador">
						<Quoter></Quoter>
					</Route>
					<Route path="/recorrido-virtual">
						<Recorrido></Recorrido>
					</Route>
					<Route path="*">
						<NotFound></NotFound>
					</Route>
				</Switch>
				<Footer></Footer>
			</div>
		</Router>
	);
}

export default App;
