import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import logoWhite from '../assets/logo_white.svg';

import '../scss/footer.scss';

class Footer extends Component {
    render() {
        return (
            <div>
                <footer className="py-4 px-3">
                    <div className="container-fluid w14 w-lg-90 position-relative">
                        <div className="row align-items-center">
                            <div className="col-12 col-md-5 col-lg-4 m30 mb-md-0 text-center text-md-left d-flex align-items-center justify-content-center justify-content-md-start small-p">
                                <img src={logoWhite} alt="Rocio" className="mr-3 logo-footer" ></img>
                                <p className="mb-0">Costa Mayab. Todos los derechos reservados®</p>
                            </div>
                            <div className="col-12 col-md-4 col-lg-4 m30 mb-md-0 text-center p-md-0">
                                <p className="mb-0">D. Calle 24 Num 120 Loc 12 X 21 Y 23 Col México.</p>
                                <p className="mb-0">T. (999) 900 8019</p>
                                <p className="mb-0">C. carolina@estructurainmobiliaria.mx</p>
                            </div>
                            <div className="col-12 col-md-3 col-lg-4 m30 mb-md-0 text-center text-md-right">
                                <a target="_blank" href="https://www.facebook.com/CostaMayab"><img src="https://rocioliving.com/img/icon/icon-facebook.png" alt="Facebook"></img></a>
                                <a target="_blank" href="https://instagram.com/costamayab?utm_medium=copy_link" className="ml-3"><img src="https://rocioliving.com/img/icon/icon-instagram.png" alt="Instagram"></img></a>
                                <a href="#!" className="d-block">
                                    <FormattedMessage 
                                        id="app.aviso_privacidad"
                                        defaultMessage="Aviso de Privacidad"    
                                    ></FormattedMessage>
                                </a>
                                <a href="#!" className="d-block">
                                    <FormattedMessage
                                        id="app.terminos_y_condiciones"
                                        defaultMessage="Términos y Condiciones"
                                    ></FormattedMessage>
                                </a>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        );
    }
}

export default Footer;