import React, { Component } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/components/pagination/pagination.scss';
import { Container, Row, Col } from 'react-bootstrap';
import {FormattedMessage} from 'react-intl';

//Imagenes
import slider1 from './assets/slide1.jpg';
import slider2 from './assets/slide2.jpg';
import slider3 from './assets/slide3.jpg';
import slider4 from './assets/mapa.png';
// import slider2 from './assets/slider.jpg';
// import slider3 from './assets/slider3.jpeg';
// import slider4 from './assets/slider4.jpeg';
import isotipo_ultima_imagen from './assets/Isotipo_ultima_imagen.png';
import image_3 from './assets/imagen03.png';
import casas from './assets/casas.svg';
import playa from './assets/playa.svg';
import carreteras from './assets/carreteras.svg';
import turista from './assets/turista.svg';
import mangle from './assets/mangle.svg';
import lago from './assets/lago.svg';
import hotel from './assets/hotel.svg';
import carril from './assets/carril.svg';

// Import Swiper styles
import 'swiper/swiper.scss';
import './scss/home.scss';

class Home extends Component {
    render() {
        return (
            <div>
                <Swiper
                slidesPerView={1}
                autoHeight
                >
                    <SwiperSlide>
                        <div className="iframe-container" style={{position:"relative"}}>
                            <iframe title="video" src="https://player.vimeo.com/video/564793069?autoplay=1&title=0&byline=0&portrait=0" style={{position:"absolute",top:0,left:0,width:"100%",height:"100%"}} frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                        </div>
                        <script src="https://player.vimeo.com/api/player.js"></script>
                    </SwiperSlide>
                </Swiper>
                <div className="home-info">
                    <Container fluid className="w-lg-90 w14 position-relative">
                        <Row>
                            <Col className="pl-lg-5 m30 text-center d-md-flex flex-column justify-content-center align-items-center px-3">
                                <img src={isotipo_ultima_imagen} className="mb-5 logo-home-info" alt="isotipo1"></img>
                                <h4 className="text-white din-alternate">
                                    <FormattedMessage
                                        id="app.get_property"
                                        defaultMessage="¡Adquiere una propiedad en una de las zonas con mayor potencial!"
                                    ></FormattedMessage>
                                </h4>
                                <h1 className="text-white bars din-alternate">
                                    <FormattedMessage
                                        id="app.ecoturistico"
                                        defaultMessage="ECO-TURISTICO"
                                    ></FormattedMessage>
                                    <br></br>
                                    <FormattedMessage
                                        id="app.de_mexico"
                                        defaultMessage="DE MÉXICO"
                                    ></FormattedMessage>
                                </h1>
                            </Col>
                            <Col className="col-12 col-md-10 col-lg-7 px-5">
                                <Row className="align-items-end m-fh">
                                    <Col className="text-right din-alternate text-blue-3">
                                        <h4>Somos la opción para hacer crecer tu dinero en el estado más seguro y con</h4>
                                        <hr></hr>
                                        <h3>MAYOR CRECIMIENTO <br></br> DEL PAÍS</h3>
                                    </Col>
                                    <Col sm="12" lg="5" md="2">
                                        <img src={image_3} alt="image2" style={{width:"100%"}}></img>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Container fluid className="my-lg-5 py-lg-5 px-lg-5 sm-np">
                    <Swiper
                    slidesPerView={1}
                    pagination={{ clickable: true }}
                    autoplay
                    autoHeight
                    className="mt-5 mb-3"
                    >
                        <SwiperSlide><img src={slider1} alt="Slider" style={{width:"100%"}}></img></SwiperSlide>
                        <SwiperSlide><img src={slider2} alt="Slider" style={{width:"100%"}}></img></SwiperSlide>
                        <SwiperSlide><img src={slider3} alt="Slider" style={{width:"100%"}}></img></SwiperSlide>
                        <SwiperSlide><img src={slider4} alt="Slider" style={{width:"100%"}}></img></SwiperSlide>
                    </Swiper>
                </Container>
                <div className="home-more-info text-white">
                    <Container fluid className="my-lg-5 pt-lg-5 pt-5">
                        <Row>
                            <Col sm="12" md="4">
                                <img src={isotipo_ultima_imagen} style={{maxWidth:"100%"}} alt="isotipo"></img><br></br>
                                <h3 className="bars din-alternate">
                                    <FormattedMessage
                                        id="app.why_choose_costa_mayab"
                                        defaultMessage="¿Porqué elegir Costa Mayab?"
                                    ></FormattedMessage>
                                </h3>
                                <Row className="mt-4">
                                    <Col>
                                        Precio <br></br>
                                        Desde <b>$145.00 pesos m&sup2;</b>
                                    </Col>
                                </Row>
                                <p className="mt-4">
                                Enganche desde <b>$9,135.00</b> <br></br> 
                                Mensualidades desde <b>$1,437.00</b> <br></br> 
                                </p>
                            </Col>
                            <Col sm="12" md="4">
                                <div className="flexi align-items-center mb-5">
                                    <img className="px-3" src={playa} alt="casas"></img>
                                    <p className="mb-0 px-3">
                                        <FormattedMessage 
                                            id="app.a_menos_de" 
                                            defaultMessage="A menos de "
                                        ></FormattedMessage>

                                        <b>
                                            <FormattedMessage id="app.ocho_minutos" defaultMessage="8 minutos"></FormattedMessage>
                                        </b>

                                        <FormattedMessage id="app.de_la_playa_de_chabihau" defaultMessage=" de la playa de Chabihau."></FormattedMessage>
                                    </p>
                                </div>
                                <div className="flexi align-items-center mb-5">
                                    <img className="px-3" src={casas} alt="casas"></img>
                                    <p className="mb-0 px-3">
                                        <FormattedMessage 
                                            id="app.se_encuentra_dentro_de_la_mancha_urbana_del_poblado_de"
                                            defaultMessage="Se encuentra dentro de la mancha urbana del poblado de "
                                        ></FormattedMessage>
                                        <b>
                                            <FormattedMessage
                                                id="app.yobain"
                                                defaultMessage="Yobaín."
                                            ></FormattedMessage>
                                        </b>
                                    </p>
                                </div>
                                <div className="flexi align-items-center mb-5">
                                    <img className="px-3" src={carreteras} alt="carretera"></img>
                                    <p className="mb-0 px-3">
                                        <FormattedMessage
                                            id="app.cuenta_con"
                                            defaultMessage="Cuenta con "
                                        ></FormattedMessage>
                                        <b>
                                            <FormattedMessage
                                                id="app.carretera"
                                                defaultMessage="carretera"
                                            ></FormattedMessage>
                                        </b>
                                        <FormattedMessage id="app.de_acceso_a_los_lotes" defaultMessage=" de acceso a los lotes."></FormattedMessage>
                                    </p>
                                </div>
                                <div className="flexi align-items-center mb-5">
                                    <img className="px-3" src={turista} alt="turista"></img>
                                    <p className="mb-0 px-3">
                                        <FormattedMessage
                                            id="app.proyectos"
                                            defaultMessage="Proyectos "
                                        ></FormattedMessage>
                                        <b>
                                            <FormattedMessage
                                                id="app.eco_turisticos"
                                                defaultMessage="eco-turísticos"
                                            ></FormattedMessage>
                                        </b>
                                        <FormattedMessage
                                            id="app.en_los_alrededores"
                                            defaultMessage=" en los alrededores."
                                        ></FormattedMessage>
                                    </p>
                                </div>
                            </Col>
                            <Col sm="12" md="4">
                                {/* <div className="flexi align-items-center mb-5">
                                    <img className="px-3" src={mangle} alt="manglar"></img>
                                    <p className="mb-0 px-3">
                                        <FormattedMessage
                                            id="app.ubicado_en_la"
                                            defaultMessage="Ubicado en la "
                                        ></FormattedMessage>
                                        <b>
                                            <FormattedMessage
                                                id="app.ruta_del_manglar"
                                                defaultMessage="ruta del manglar."
                                            ></FormattedMessage>
                                        </b>
                                    </p>
                                </div> */}
                                <div className="flexi align-items-center mb-5">
                                    <img className="px-3" src={lago} alt="lago"></img>
                                    <p className="mb-0 px-3">
                                        <FormattedMessage
                                            id="app.zona_de"
                                            defaultMessage="Zona de "
                                        ></FormattedMessage>
                                        <b>
                                            <FormattedMessage id="app.cenotes_y_eco_hoteles" defaultMessage="cenotes y eco-hoteles."></FormattedMessage>
                                        </b>
                                    </p>
                                </div>
                                <div className="flexi align-items-center mb-5">
                                    <img className="px-3" src={hotel} alt="casas"></img>
                                    <p className="mb-0 px-3">
                                        <FormattedMessage
                                            id="app.desarrollos_inmobiliarios_en_los_alrededores"
                                            defaultMessage="Desarrollos inmobiliarios en los alrededores con "
                                        ></FormattedMessage>
                                        <b>
                                            <FormattedMessage
                                                id="app.alta_utilidad"
                                                defaultMessage="alta utilidad"
                                            ></FormattedMessage>
                                        </b>
                                        <FormattedMessage
                                            id="app.en_airbnb"
                                            defaultMessage=" en Airbnb."
                                        ></FormattedMessage>
                                    </p>
                                </div>
                                <div className="flexi align-items-center mb-5">
                                    <img className="px-3" src={carril} alt="casas"></img>
                                    <p className="mb-0 px-3">
                                        <b>
                                            <FormattedMessage
                                                id="app.cuenta_con_calle_blanca"
                                                defaultMessage="Cuenta con calle blanca "
                                            ></FormattedMessage>
                                        </b>
                                        <FormattedMessage
                                            id="app.en_todo_el_desarrollo"
                                            defaultMessage="en todo el desarrollo"
                                        ></FormattedMessage>
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        );
    }
}

export default Home;