import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './scss/main.scss';

import {IntlProvider} from 'react-intl';
import English from './lang/en.json';
import Spanish from './lang/es.json';

let locale;
if(localStorage.getItem('language')){
  locale = localStorage.getItem('language');
}else{
  locale = navigator.language
}
// console.log("Local Storage", localStorage.getItem('language'));

let lang;
if ((locale==="en-US") || (locale==="en")) {
   lang = English;
} else {
   if (locale === "es") {
       lang = Spanish;
   }
}

ReactDOM.render(
  <React.StrictMode>
    <IntlProvider locale={locale} key={locale} messages={lang} defaultLocale="es">
      <App locale={locale}/>
    </IntlProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
