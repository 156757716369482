import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import { GoogleMap, LoadScript } from '@react-google-maps/api';
// import axios from 'axios';
// import Swal from 'sweetalert2'

import logoWhite from './assets/logo_white.svg';

import lago from './assets/lago-contacto.svg';
import hotel from './assets/hotel-contacto.svg';
import mangle from './assets/mangle-contacto.svg';
import mapa from './assets/mapa.svg';

// import './scss/main.scss';
import './scss/contact.scss';

const containerStyle = {
    width: '100%',
    height: '400px'
};
  
const center = {
    lat: 21.2488784,
    lng: -89.1162344
};

class Contact extends Component {
    constructor(props){
        super(props);
        this.state = {
            name: "",
            email: "",
            phone_number: "",
            message: "",
            sending: false
        }
        // this.handleSubmit = this.handleSubmit.bind(this);
    }

    // handleSubmit(event){
    //     event.preventDefault();
    //     let form = document.querySelector('.validate');
    //     if (form.checkValidity() === false) {
    //         //Campos invalidos
    //         event.preventDefault();
    //         event.stopPropagation();
    //     }else{
    //         this.setState({...this.state, sending: true});
    //         axios.post(`${process.env.REACT_APP_API_URL}contacto`, this.state)
    //         .then(res => {
    //             console.log(res.status);
    //             if((res.status === 200) && (res.data.success)){
    //                 // Mostramos mensaje enviado
    //                 if(localStorage.getItem('language') === 'es'){
    //                     Swal.fire(
    //                         'Operación exitosa',
    //                         'Mensaje enviado',
    //                         'success'
    //                     )
    //                 }else{
    //                     Swal.fire(
    //                         'Success',
    //                         'Email sent',
    //                         'success'
    //                     )
    //                 }
    //             }else{
    //                 if(localStorage.getItem('language') === 'es'){
    //                     Swal.fire(
    //                         'Oops!',
    //                         'Lo sentimos, algo salió mal',
    //                         'error'
    //                     )
    //                 }else{
    //                     Swal.fire(
    //                         'Oops!',
    //                         'Sorry, something went wrong',
    //                         'error'
    //                     )
    //                 }
    //             }
    //             this.setState({...this.state, sending: false});
    //         })
    //         .catch(err => {
    //             console.log(err);
    //             Swal.fire(
    //                 'Oops!',
    //                 'Lo sentimos, algo salió mal',
    //                 'error'
    //             )
    //             this.setState({...this.state, sending: false});
    //         });
    //     }
    //     form.classList.add('was-validated');
    // }
    
    render() {
        return (
            <div>
                <section id="location">
                    <Container fluid>
                        <div className="row justify-content-end justify-content-lg-start">
                            <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4 pt-5 pl-5 pb-5 m-lg-80 z-index-1">
                                <h2 className="text-uppercase din-alternate">
                                    <FormattedMessage
                                        id="app.nuestra_ubicacion"
                                        defaultMessage="NUESTRA UBICACIÓN"
                                    ></FormattedMessage>
                                </h2>
                                <p>
                                    <FormattedMessage
                                        id="app.dentro_de_la_mancha_urbana_del_poblado_de_yobain_a_menos_de_8_minutos_de_la_playa"
                                        defaultMessage="Dentro de la mancha urbana del poblado de Yobaín, a menos de 8 minutos de la playa Chabihau y "
                                    ></FormattedMessage>
                                    <b>
                                        <FormattedMessage
                                            id="app.cerca_de"
                                            defaultMessage="cerca de:"
                                        ></FormattedMessage>
                                    </b>
                                </p>
                                <ul className="list-unstyled d-flex justify-content-center">
                                    <li className="text-center m-3">
                                        <img src={lago} alt="hospitales"></img>
                                        <p className="mb-0">
                                            <FormattedMessage
                                                id="app.cenotes"
                                                defaultMessage="Cenotes"
                                            ></FormattedMessage>
                                        </p>
                                    </li>
                                    <li className="text-center m-3">
                                        <img src={hotel} alt="plazas"></img>
                                        <p className="mb-0">
                                            <FormattedMessage
                                                id="app.ecohoteles"
                                                defaultMessage="Eco-hoteles"
                                            ></FormattedMessage>
                                        </p>
                                    </li>
                                </ul>
                                <img className="sello" src={logoWhite} alt="Sello" />
                            </div>
                            <div className="col-md-6 col-lg-8 text-center pl-0 pr-0">
                                <img src={mapa} alt="Croquis" className="w-100" />
                            </div>
                        </div>
                    </Container>
                </section>
                <section id="location-2">
                    <Container fluid className="pb-5">
                        <Row>
                            <Col xl="6" md="12" sm="12" className="pt-5 pl-lg-5 text-center text-white mb-5 custom-pd">
                                <h3 className="din-alternate">
                                    <FormattedMessage
                                        id="app.contactanos"
                                        defaultMessage="CONTÁCTANOS"
                                    ></FormattedMessage>
                                </h3>
                                <p className="mb-0">
                                    <FormattedMessage id="app.visitanos" defaultMessage="Visitanos en nuestro centro de ventas"></FormattedMessage> <br></br>
                                    D. Calle 24 Num 120 Loc 12 X 21 Y 23 Col México. <br></br>
                                    T. (999) 900 8019 <br></br>
                                    C. carolina@estructurainmobiliaria.mx <br></br>
                                </p>
                                <br></br>
                                <br></br>
                                <Form>
                                    <Row>
                                        <Col md="12">
                                            <Form.Group controlId="formName">
                                                <Form.Control type="text" placeholder="Nombre" />
                                            </Form.Group>
                                        </Col>
                                        <Col md="6">
                                            <Form.Group controlId="formBasicEmail">
                                                <Form.Control type="email" placeholder="Correo" />
                                            </Form.Group>
                                        </Col>
                                        <Col md="6">
                                            <Form.Group controlId="formBasicEmail">
                                                <Form.Control type="tel" placeholder="Telefono" />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group controlId="exampleForm.ControlTextarea1">
                                                <Form.Control as="textarea" rows={3} placeholder="Mensaje" />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Button className="mt-3">Enviar</Button>
                                </Form>
                            </Col>
                            <Col xl="6" md="12" sm="12" className="pt-5">
                                <LoadScript
                                    googleMapsApiKey="AIzaSyBuu1Q0hHmlO30h7YRDZ0mWYof-SM-edns"
                                >
                                    <GoogleMap
                                    mapContainerStyle={containerStyle}
                                    center={center}
                                    zoom={15}
                                    >
                                    { /* Child components, such as markers, info windows, etc. */ }
                                    </GoogleMap>
                                </LoadScript>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </div>
        );
    }
}

export default Contact;