import React, { Component } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import '@fancyapps/fancybox';
import '@fancyapps/fancybox/src/css/core.css';
// import Pagination from './components/Pagination';

import './scss/gallery.scss';

import axios from 'axios';

class Gallery extends Component {
    constructor(props){
        super(props);
        this.state = {
            categories: [],
            slides: []
        }
    }

    componentDidMount(){
        //Buscamos los albunes
        axios.get('http://costamayab.naboo.mx/api/v1/avances', {
            headers: {
                apikey: 'SSjPNI57Zhc63Y7edQGN6sU8oaXGx7JT'
            }
        })
        .then(res => {
            this.setState(prevState => {
                return {
                    ...prevState,
                    categories: res.data.galerias
                }
            })
            this.loadGallery(res.data.galerias[0].gallery_id);
        })
        .catch(err => {
            console.log(err);
        })
        
    }

    showContent(){
        if(this.state.slides.length > 0){
            return this.state.slides.map(slide => {
                return <Col sm="12" md="12" lg="3" className="mb-4"><a href={slide.cover} data-fancybox><img src={slide.cover} alt="" style={{width:"100%"}} /></a></Col>;
            })
        }else{
            return <Col className="text-center"><h5><FormattedMessage id="app.loading" defaultMessage="Cargando..."></FormattedMessage></h5></Col>
        }
    }

    loadGallery(id){
        axios.get(`http://costamayab.naboo.mx/api/v1/avances/${id}/contenido`, {
                headers: {
                    apikey: 'SSjPNI57Zhc63Y7edQGN6sU8oaXGx7JT'
                }
            })
            .then(response => {
                this.setState(prevState =>{
                    return{
                         ...prevState,
                         slides : response.data.slides.data,
                         active: id 
                    }
                })
            })
            .catch(err => {
                console.log(err);
            })
    }

    onPageChanged(data, id){
        // const { currentPage, totalPages, pageLimit } = data;
      
        // axios.get(`/http://costamayab.naboo.mx/api/v1/avances/id/${id}/contenido?page=${currentPage}&limit=${pageLimit}`)
        // .then(response => {
        //     // const currentCountries = response.data.countries;
            
        //     // this.setState({ currentPage, currentCountries, totalPages });
        // });
    }

    render() {
        return (
            <div id="gallery">
                <Container fluid className="pt-5">
                    <h1 className="text-center text-uppercase">
                        <FormattedMessage
                            id="app.gallery"
                            defaultMessage="Galería"
                        ></FormattedMessage>
                    </h1>
                    <br></br>
                    <br></br>
                    <ul className="list-unstyled d-flex justify-content-center">
                        {this.state.categories.map((cat, index) => {
                            return <li key={cat.gallery_id}><Button className={(cat.gallery_id === this.state.active) ? 'active' : ''} onClick={() => this.loadGallery(cat.gallery_id)}>{cat.title}</Button></li>
                        })}
                    </ul>
                    <br></br>
                    <br></br>
                    <Row>
                        {this.showContent()}
                    </Row>
                    {/* <div className="d-flex flex-row py-4 align-items-center">
                        <Pagination totalRecords={18} pageLimit={18} pageNeighbours={3} onPageChanged={this.onPageChanged} />
                    </div> */}
                </Container>
                
            </div>
        );
    }
}

export default Gallery;