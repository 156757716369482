import React, { Component } from 'react';

import './scss/recorrido.scss';
import fill_up from './assets/fill_up.svg';
import fill_down from './assets/fill_down.svg';
import { FormattedMessage } from 'react-intl';

class Recorrido extends Component {
    render() {
        return (
            <div id="recorrido">
                <div className="container-fluid">
                    <img className="fill up" src={fill_up} alt="fill_down"></img>
                    <h1 className="text-center position-relative">
                        <FormattedMessage
                            id="app.recorrido_virtual"
                            defaultMessage="RECORRIDO VIRTUAL"
                        ></FormattedMessage>
                    </h1>
                    <img className="fill down" src={fill_down} alt="fill_down"></img>
                    <br></br>
                    <div style={{position:"relative"}}>
                        <iframe title="Recorrido" src="https://api2.enscape3d.com/v1/view/3a42cdcc-ad7c-43bd-91ff-d0b358848acb" style={{width:"100%", height:"600px", border:"none"}}></iframe>
                    </div>
                </div>
            </div>
        );
    }
}

export default Recorrido;